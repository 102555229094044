.spinner {
  height: 500px;
  width: 30px;
  animation: spinner 0.7s linear infinite;
  color: #f44336;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
